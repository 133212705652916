import React, { useState, useEffect, useRef } from "react";
import QRCode from "qrcode";

import "./index.scss";
import { SpinnerPage } from "components";

const IMG_WIDTH = 1500 / 1;
const IMG_HEIGHT = 2236 / 1;

type ImageParams = {
  x: number;
  y: number;
  w: number;
  h: number;
};

interface ImageSource extends ImageParams {
  src: string;
}

interface ImageSourceElement extends ImageParams {
  src: HTMLImageElement;
}

/**
 * Promisify loading an image
 * @param {String} src The web location of the image
 * @returns {Promise} A Promise that will resolve to an Image
 */
function loadImage(img: ImageSource): Promise<ImageSourceElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "annoymous";
    image.src = img.src;
    image.addEventListener("load", () => resolve({ ...img, src: image }));
    image.addEventListener("error", reject);
  });
}

export const PosterPage: React.FC = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = "生成海报";
  }, []);

  const [posterDataUrl, setPosterDataUrl] = useState<string>();
  const posterRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    async function updateCanvas() {
      const canvas = posterRef.current;
      if (canvas === null) return;

      const getContext = () => canvas.getContext("2d");
      const ctx = getContext();

      const qrCode1 = await QRCode.toDataURL("https://baidu.com", {
        margin: 1
      });

      const imageSources: ImageSource[] = [
        {
          src: require("./poster_background.png"),
          x: 0,
          y: 0,
          w: IMG_WIDTH,
          h: IMG_HEIGHT
        },
        {
          src: qrCode1,
          x: 0.6 * IMG_WIDTH,
          y: 0.79 * IMG_HEIGHT,
          w: 0.2 * IMG_WIDTH,
          h: 0.2 * IMG_WIDTH
        },
        {
          // 下图存放于腾讯云 COS 中，COS 已设置 CROS
          // 设置链接 https://cloud.tencent.com/document/product/436/11488
          src:
            "https://wx.qlogo.cn/mmopen/vi_32/o5uh5QbtNp3dT0BiajQ87iaULr6f1goIIxezaic4cPjBjmokvrnrecYFORf9J4MVOQnn5DenwagRcBjIesHIJCWWA/132",
          // 如果引用其他图片则  Console 报错
          // src: "https://mdn.mozillademos.org/files/14295/CORS_principle.png",
          x: 0.65 * IMG_WIDTH,
          y: 0.22 * IMG_HEIGHT,
          w: 0.18 * IMG_WIDTH,
          h: 0.18 * IMG_WIDTH
        }
      ];

      Promise.all(imageSources.map(i => loadImage(i)))
        .then(imgs => {
          if (ctx === null) return;
          imgs.forEach(({ src, x, y, w, h }) => {
            ctx.drawImage(src, x, y, w, h);
          });
          ctx.font = "100px serif";
          ctx.fillText("闫鹏强", 0.5 * IMG_WIDTH, 0.47 * IMG_HEIGHT);
          ctx.fillText("10", 0.56 * IMG_WIDTH, 0.57 * IMG_HEIGHT);

          const canvas = posterRef.current;
          if (canvas === null) return;
          setPosterDataUrl(canvas.toDataURL("image/png"));
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log("error occurred");
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
    updateCanvas();
  }, []);

  return (
    <div className="poster-page">
      <div className="poster">
        <canvas
          ref={posterRef}
          width={IMG_WIDTH}
          height={IMG_HEIGHT}
          style={{ display: "none" }}
        />
        {!posterDataUrl && <SpinnerPage />}
        {!!posterDataUrl && (
          <>
            <img src={posterDataUrl} alt="poster" className="poster-img" />
            <div className="tips">
              快常按图片保存到手机
              <br />
              然后发送到朋友圈吧～
            </div>
          </>
        )}
      </div>
    </div>
  );
};
