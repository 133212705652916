import React, { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { Indicator } from "./indicator.component";
import "./index.scss";
import {
  Question,
  getRandomQuestion,
  getQuestionCode,
  SubmitQuestionData
} from "api/api";
import { SpinnerPage, OptionsComponent } from "components";
import shieldImg from "./shield.jpg";

const MAX_QUESTIONS_COUNT = 10;

interface State {
  isFetching: boolean;
  currentQuestionIdx: number;
  currentQuestion?: Question;
  questionsData: SubmitQuestionData[];
  paperCode: string;
}

type Action =
  | { type: "increaseCurrentQuestionsIdx" }
  | { type: "setPaperCode"; code: string }
  | { type: "setIsFetching"; isFetching: boolean }
  | { type: "setCurrentQuestion"; question: Question }
  | { type: "answerAQuestion"; submitQuestionData: SubmitQuestionData };

const initialState: State = {
  isFetching: false,
  currentQuestionIdx: 0,
  paperCode: "",
  questionsData: [
    {
      questionId: undefined,
      answerId: undefined
    }
  ]
};

function reducer(state: State, action: Action): State {
  const { currentQuestionIdx } = state;
  switch (action.type) {
    case "increaseCurrentQuestionsIdx":
      return {
        ...state,
        currentQuestionIdx:
          currentQuestionIdx + 1 < MAX_QUESTIONS_COUNT
            ? currentQuestionIdx + 1
            : currentQuestionIdx
      };
    case "setPaperCode":
      return {
        ...state,
        paperCode: action.code
      };
    case "setIsFetching": {
      return {
        ...state,
        isFetching: action.isFetching
      };
    }
    case "setCurrentQuestion": {
      const newQuestionsData = state.questionsData;
      const question = action.question;
      newQuestionsData[currentQuestionIdx] = {
        questionId: question.id,
        answerId: undefined
      };
      return {
        ...state,
        currentQuestion: action.question,
        questionsData: newQuestionsData
      };
    }
    case "answerAQuestion": {
      const newQuestionsData = state.questionsData;
      newQuestionsData[currentQuestionIdx] = action.submitQuestionData;

      return {
        ...state,
        questionsData: newQuestionsData
      };
    }
    default:
      throw new Error();
  }
}

export const GeneratePaperPage: React.FC<{}> = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = "创建题目";
  }, []);

  const history = useHistory();

  const [
    { currentQuestionIdx, questionsData, currentQuestion, isFetching },
    reactDispatch
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchPaperCode() {
      const code = await getQuestionCode();
      reactDispatch({ type: "setPaperCode", code });
    }
    fetchPaperCode();
  }, []);

  useEffect(() => {
    async function fetchQuestion() {
      // fetch unique question
      const questionsIds = questionsData.map(({ questionId }) => questionId);
      let currentQuestion;
      let currentQuestionId;
      do {
        currentQuestion = await getRandomQuestion();
        currentQuestionId = currentQuestion.id;
        console.log({ currentQuestionId });
      } while (questionsIds.includes(currentQuestionId));

      reactDispatch({ type: "setIsFetching", isFetching: true });
      reactDispatch({ type: "setCurrentQuestion", question: currentQuestion });
      reactDispatch({ type: "setIsFetching", isFetching: false });
    }
    fetchQuestion();
  }, [currentQuestionIdx]);

  function nextQuestion() {
    if (questionsData[currentQuestionIdx].answerId === undefined) {
      alert("请选择选项哦～");
      return;
    }
    if (currentQuestionIdx + 1 === MAX_QUESTIONS_COUNT) {
      history.push("/poster");
      return;
    }
    reactDispatch({ type: "increaseCurrentQuestionsIdx" });
    reactDispatch({ type: "setIsFetching", isFetching: true });
  }

  function optionOnClick(questionId: number, answerId: number) {
    reactDispatch({
      type: "answerAQuestion",
      submitQuestionData: {
        questionId,
        answerId
      }
    });
  }
  return (
    <div className="generate-paper-page">
      {isFetching && <SpinnerPage />}
      {!isFetching &&
        currentQuestion !== undefined &&
        currentQuestion.answerList !== undefined && (
          <div className="content">
            <Indicator
              count={MAX_QUESTIONS_COUNT}
              current={currentQuestionIdx + 1}
            />

            <div className="tips">
              <img className="img" src={shieldImg} />
              <span className="text">
                题库由<span className="brand"> 牵千 </span>100%原创
              </span>
            </div>

            <div className="info">
              <div>
                <div className="replace">换一题</div>
                <div className="question">{currentQuestion?.content}</div>
              </div>

              <OptionsComponent
                options={currentQuestion.answerList.map(
                  ({ answer, id, questionId }) => ({
                    label: answer,
                    isChecked:
                      questionsData[currentQuestionIdx].answerId === id,
                    onClick: () => {
                      optionOnClick(questionId, id);
                    }
                  })
                )}
              />
            </div>

            {currentQuestionIdx + 1 < MAX_QUESTIONS_COUNT && (
              <div className="next" onClick={nextQuestion}>
                下一题
              </div>
            )}
            {currentQuestionIdx + 1 === MAX_QUESTIONS_COUNT && (
              <div className="next" onClick={nextQuestion}>
                提交
              </div>
            )}
          </div>
        )}
    </div>
  );
};
