import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Question, Paper } from "../../api/api";

export const initialState: Paper[] = [];

export const slice = createSlice({
  name: "SetQuestions",
  initialState,
  reducers: {
    generatePaper: (state, action: PayloadAction<Question[]>) => {
      state.push(action.payload);
    }
  }
});

export const { generatePaper } = slice.actions;

export default slice.reducer;
