import React from "react";

import "./index.scss";
import { HeaderRoundButton, HeaderImage } from "components";
import BigButton from "./BigButton";
import SmallButton from "./SmallButton";

export const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <HeaderRoundButton
        to="/how-to-play"
        title="怎么玩？"
        style={{
          backgroundColor: "#333",
          position: "absolute",
          zIndex: 1,
          left: "1.5rem",
          top: "1.5rem"
        }}
      />
      <HeaderRoundButton
        to="/customer-service"
        title="联系客服"
        style={{
          backgroundColor: "red",
          position: "absolute",
          zIndex: 1,
          right: "1.5rem",
          top: "1.5rem"
        }}
      />

      <HeaderImage />

      <div className="buttons">
        <BigButton title="我要出题" />
        <div className="row">
          <SmallButton title="出题记录" to="/generate-paper-records" />
          <SmallButton title="答题记录" to="/answer-questions-records" />
        </div>
      </div>
    </div>
  );
};
