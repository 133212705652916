import React from "react";

import "./index.scss";
import header from "./header.png";

export const HeaderImage: React.FC = () => {
  return (
    <div className="header">
      <img className="header-image" src={header} />
    </div>
  );
};
