import React from "react";

import "./select-answer.component.scss";

interface SelectAnswerComponentProps {
  children: React.ReactElement;
}

export const SelectAnswerComponent: React.FC<SelectAnswerComponentProps> = ({
  children
}) => {
  return <div className="select-answer-component">{children}</div>;
};
