import React from "react";

import "./answerer.component.scss";

interface AnswererComponentProps {
  avatar: string;
  content: string;
}

export const AnswererComponent: React.FC<AnswererComponentProps> = ({
  avatar,
  content
}) => {
  return (
    <div className="answer-item-indicator">
      <div className="bubble">{content}</div>
      <img src={avatar} className="avatar" />
    </div>
  );
};
