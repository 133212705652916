import React, { useState, useRef, useEffect } from "react";

import "./index.scss";
import { OptionsComponent, SpinnerPage } from "components/index";
import { HeaderComponent } from "./header.component";
import { NumberIndicatorComponent } from "./number-indicator.component";
import { QuestionerComponent } from "./questioner.component";
import { AnswererComponent } from "./answerer.component";
import { SelectAnswerComponent } from "./select-answer.component";
import { Question, fetchQuestions } from "api/api";
import { useHistory } from "react-router-dom";
import { answerQuestions } from "../../features/answer-questions/answer-questions.slice";
import { useDispatch } from "react-redux";

const QUESTIONER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/Jh6EtkuDZzKDCxHg6q35uBVdqW8AI2lauEd9X5llSZ4ic5yNicdnj2asnOOnKvsHmGHy7y4aPcw6OkmrDItw9yJw/132"
};

const ANSWERER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKUaGkMctgfsh9WDZWGXvtpe68lCDTAFwmut0Lficc0UU1uJ8TcfC0ic3rdgIAtqSY5ibaBG6Z8qvzibw/132"
};

interface State {
  currentQuestionIdx: number;
  totalQuestionsCount: number;
  questions: Question[];
}

export const AnswerQuestionsPage: React.FC = () => {
  const MAX_QUESTIONS_COUNT = 4;

  const initialState = {
    questions: [],
    totalQuestionsCount: 0,
    currentQuestionIdx: 0
  };
  const [
    { questions, totalQuestionsCount, currentQuestionIdx },
    updatePageState
  ] = useState<State>(initialState);

  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    async function fetchEverything() {
      try {
        setIsFetching(true);
        const questions = await fetchQuestions(MAX_QUESTIONS_COUNT);
        console.log(questions);
        updatePageState({
          questions,
          currentQuestionIdx: 0,
          totalQuestionsCount: questions.length
        });
      } catch (err) {
        // console.error(err);
        // setIssuesError(err)
      } finally {
        // setIsLoading(false)
        setIsFetching(false);
      }
    }
    fetchEverything();
  }, [MAX_QUESTIONS_COUNT]);

  // scroll to bottom
  const answerPanelBottomRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    const answerPanelBottomDiv = answerPanelBottomRef.current;
    if (answerPanelBottomDiv === null) return;
    answerPanelBottomDiv.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [questions]);

  const dispatch = useDispatch();

  // navigate when finish the quiz
  const [hasFinishedQuiz, setQuizFinished] = useState(false);
  const history = useHistory();
  const navigate = () => {
    if (hasFinishedQuiz) {
      history.push("/quiz-result");
      dispatch(answerQuestions(questions));
    }
  };
  useEffect(navigate, [hasFinishedQuiz]);

  return (
    <div className="answer-questions-page">
      {isFetching && <SpinnerPage />}
      {!isFetching && questions.length > 0 && (
        <div>
          <HeaderComponent
            answererAvatar={ANSWERER.avatar}
            questionerAvatar={QUESTIONER.avatar}
          />

          <div className="answer-panel">
            {questions
              .slice(0, currentQuestionIdx + 1)
              .map(({ question, userSelected }, idx) => (
                <div key={idx}>
                  <NumberIndicatorComponent
                    content={`第 ${idx + 1}
                题 / 共 ${totalQuestionsCount} 题`}
                  />
                  <QuestionerComponent
                    avatar={QUESTIONER.avatar}
                    content={question}
                  />
                  {userSelected !== undefined && (
                    <AnswererComponent
                      avatar={ANSWERER.avatar}
                      content={userSelected}
                    />
                  )}
                </div>
              ))}
            <div ref={answerPanelBottomRef} />
          </div>

          <SelectAnswerComponent>
            <>
              <div className="select-answer-tip">请选择答案</div>

              <OptionsComponent
                options={questions[currentQuestionIdx].options.map(option => ({
                  label: option,
                  isChecked: false,
                  onClick: () => {
                    updatePageState(
                      ({
                        questions,
                        currentQuestionIdx,
                        totalQuestionsCount
                      }) => {
                        const newQuestions = [...questions];
                        newQuestions[currentQuestionIdx].userSelected = option;

                        if (currentQuestionIdx == totalQuestionsCount - 1) {
                          setQuizFinished(true);
                        }

                        return {
                          questions: newQuestions,
                          totalQuestionsCount,
                          currentQuestionIdx:
                            currentQuestionIdx < totalQuestionsCount - 1
                              ? currentQuestionIdx + 1
                              : currentQuestionIdx
                        };
                      }
                    );
                    console.log(option);
                  }
                }))}
              />
            </>
          </SelectAnswerComponent>
        </div>
      )}
    </div>
  );
};
