import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  HomePage,
  GeneratePaperPage,
  PosterPage,
  HowToPlayPage,
  CustomerServicePage,
  AnswerQuestionsPage,
  QuizResultPage,
  GeneratePaperRecordsPage,
  AnswerQuestionsRecordsPage
} from "./features/pages";
import { FooterComponent } from "components";

// Random color
// const THEME_COLOR = "#" + Math.floor(Math.random() * 16777215).toString(16);
const THEME_COLOR = "#81B88B";

export const ThemeContext = React.createContext(THEME_COLOR);

export default function App() {
  return (
    <ThemeContext.Provider value={THEME_COLOR}>
      <div
        className="root-page"
        style={{
          backgroundColor: THEME_COLOR,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div style={{ flex: 1 }}>
          <Router>
            <Switch>
              <Route path="/generate-paper" component={GeneratePaperPage} />
              <Route
                path="/generate-paper-records"
                component={GeneratePaperRecordsPage}
              />
              <Route path="/poster" component={PosterPage} />
              <Route path="/how-to-play" component={HowToPlayPage} />
              <Route path="/customer-service" component={CustomerServicePage} />
              <Route path="/answer-questions" component={AnswerQuestionsPage} />
              <Route
                path="/answer-questions-records"
                component={AnswerQuestionsRecordsPage}
              />
              <Route path="/quiz-result" component={QuizResultPage} />
              <Route path="/" component={HomePage} />
            </Switch>
          </Router>
        </div>
        <FooterComponent />
      </div>
    </ThemeContext.Provider>
  );
}
