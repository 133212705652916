import React from "react";

import "./round.scss";

export const Round: React.FC<{
  num: number;
  backgroundColor?: string;
}> = ({ num, backgroundColor = "#fff" }) => {
  return (
    <div className="round" style={{ backgroundColor }}>
      {num}
    </div>
  );
};
