import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://wx.ziyousi.com/api/question/v1/",
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
    Credential: "eb086bae4b244c09b8baaa6940baef1c"
  }
});

export interface Question {
  questionId: string;
  question: string;
  options: string[];
  answer?: string;
  userSelected?: string;

  id?: number;
  content?: string;
  status?: string;
  createTime?: string;
  updateTime?: string;
  answerList?: {
    id: number;
    answer: string;
    questionId: number;
    createTime: string;
    updateTime: string;
    isRight: boolean;
  }[];
}

export type Paper = Question[];

export async function fetchQuestions(count: number): Promise<Question[]> {
  const url = `https://nestjs-now.myncepu.now.sh/get-questions?count=${count}`;

  const questionsResponse = await axios.get<Question[]>(url);
  return questionsResponse.data;
}

interface Status {
  cost: number;
  code: number;
  message: string;
}

interface QuestionCodeData {
  code: string;
  token: string | null;
}

// get questionCode 出试卷时需要
export async function getQuestionCode() {
  const response = await axiosInstance.post<{
    status: Status;
    data: QuestionCodeData;
  }>("userQuestion/add");
  return response.data.data.code;
}

interface RandomQuestionData {
  id: number;
  content: string;
  status: null;
  createTime: string;
  updateTime: string;
  answerList: {
    id: number;
    answer: string;
    questionId: number;
    createTime: string;
    updateTime: string;
    isRight: boolean;
  }[];
}

// get questionCode 出试卷时需要
export async function getRandomQuestion() {
  const response = await axiosInstance.post<{
    status: Status;
    data: {
      question: Question;
    };
  }>("randomQuestion/get");
  return response.data.data.question;
}

export interface SubmitQuestionData {
  questionId?: number;
  answerId?: number;
}
