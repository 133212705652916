import React, { useContext } from "react";
import { range } from "lodash";
import { ThemeContext } from "../../route";

import { Round } from "./round.component";
import "./indicator.scss";

export const Indicator: React.FC<{
  count: number;
  current: number;
}> = ({ count, current }) => {
  const themeColor = useContext(ThemeContext);
  return (
    <div className="indicator-wrapper">
      <div className="rounds">
        {range(1, count + 1).map((val, idx) => (
          <Round
            num={val}
            key={val}
            backgroundColor={idx < current - 1 ? themeColor : "#fff"}
          />
        ))}
      </div>
      <div className="line" />
    </div>
  );
};
