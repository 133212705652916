import React, { useEffect } from "react";
import moment from "moment";

import "./index.scss";
import { HeaderRoundButton } from "components";
import headerImage from "./header.png";

const ANSWERER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKUaGkMctgfsh9WDZWGXvtpe68lCDTAFwmut0Lficc0UU1uJ8TcfC0ic3rdgIAtqSY5ibaBG6Z8qvzibw/132",
  nickname: "闫鹏强"
};

export const GeneratePaperRecordsPage: React.FC = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = "出题记录";
  }, []);

  return (
    <div className="generate-paper-records-page">
      <div className="menu">
        <HeaderRoundButton
          to="/"
          title="返回首页"
          style={{
            backgroundColor: "#333",
            position: "absolute",
            zIndex: 1,
            left: "1.5rem",
            top: "1.5rem"
          }}
        />
        <HeaderRoundButton
          to="/customer-service"
          title="联系客服"
          style={{
            backgroundColor: "red",
            position: "absolute",
            zIndex: 1,
            right: "1.5rem",
            top: "1.5rem"
          }}
        />
      </div>

      <div className="header">
        <img className="img" src={headerImage} />
      </div>

      <Records
        records={[
          {
            avatar: ANSWERER.avatar,
            nickname: ANSWERER.nickname,
            time: moment().format("YYYY-MM-DD HH:mm:ss")
          },
          {
            avatar: ANSWERER.avatar,
            nickname: ANSWERER.nickname,
            time: moment().format("YYYY-MM-DD HH:mm:ss")
          }
        ]}
      />
    </div>
  );
};

interface Record {
  avatar: string;
  nickname: string;
  time: string;
}

const Records: React.FC<{ records: Record[] }> = ({ records }) => {
  return (
    <div className="records-component">
      {records.length > 0 &&
        records.map(({ avatar, nickname, time }, idx) => (
          <div key={idx} className="record">
            <img src={avatar} className="avatar" />

            <div className="text">
              <div className="nickname">{nickname}</div>
              <div className="time">{time}</div>
            </div>

            <div className="buttons" style={{ margin: "0" }}>
              <HeaderRoundButton
                title="答题详情"
                to="/quiz-result"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  border: "1px solid gray"
                }}
              />
              <HeaderRoundButton
                title="获取海报"
                to="/poster"
                style={{
                  backgroundColor: "red",
                  color: "white"
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
