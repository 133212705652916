import React from "react";

import "./number-indicator.component.scss";

interface NumberIndicatorProps {
  content: string;
}

export const NumberIndicatorComponent: React.FC<NumberIndicatorProps> = ({
  content
}) => {
  return <div className="number-indicator">{content}</div>;
};
