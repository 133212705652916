import React from "react";

import "./header.component.scss";

interface HeaderComponentProps {
  questionerAvatar: string;
  answererAvatar: string;
}
export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  answererAvatar,
  questionerAvatar
}) => {
  return (
    <div className="header-component-card">
      <img src={questionerAvatar} className="avatar" />
      <div className="tip">测一测，你到底有多了解我</div>
      <img src={answererAvatar} className="avatar" />
    </div>
  );
};
