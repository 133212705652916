import React from "react";

import "./questioner.component.scss";

interface QuestionerComponentProps {
  avatar: string;
  content: string;
}

export const QuestionerComponent: React.FC<QuestionerComponentProps> = ({
  avatar,
  content
}) => {
  return (
    <div className="item-indicator">
      <img src={avatar} className="avatar" />
      <div className="bubble">{content}</div>
    </div>
  );
};
