import React from "react";
import { Link } from "react-router-dom";

import "./SmallButton.scss";

const SmallButton: React.FC<{ title: string; to: string }> = ({
  title,
  to
}) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      {" "}
      <div className="small-button">
        <span>{title}</span>
      </div>
    </Link>
  );
};

export default SmallButton;
