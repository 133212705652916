import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import "./index.scss";

const Button: React.FC<{ title: string; style: CSSProperties; to: string }> = ({
  title,
  style,
  to
}) => {
  return (
    <Link to={to} className="button" style={style}>
      {title}
    </Link>
  );
};

export default Button;
