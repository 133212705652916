import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./index.scss";
import { HeaderRoundButton, AnswerRecordListComponent } from "components";

const QUESTIONER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/Jh6EtkuDZzKDCxHg6q35uBVdqW8AI2lauEd9X5llSZ4ic5yNicdnj2asnOOnKvsHmGHy7y4aPcw6OkmrDItw9yJw/132",
  nickname: "成吉思汗小跟班"
};

const ANSWERER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKUaGkMctgfsh9WDZWGXvtpe68lCDTAFwmut0Lficc0UU1uJ8TcfC0ic3rdgIAtqSY5ibaBG6Z8qvzibw/132",
  nickname: "闫鹏强"
};

export const AnswerQuestionsRecordsPage: React.FC = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = "答题记录";
  }, []);

  const history = useHistory();

  return (
    <div className="answer-questions-records-page">
      <div className="menu">
        <HeaderRoundButton
          to="/"
          title="返回首页"
          style={{
            backgroundColor: "#333",
            position: "absolute",
            zIndex: 1,
            left: "1.5rem",
            top: "1.5rem"
          }}
        />
        <HeaderRoundButton
          to="/customer-service"
          title="联系客服"
          style={{
            backgroundColor: "red",
            position: "absolute",
            zIndex: 1,
            right: "1.5rem",
            top: "1.5rem"
          }}
        />
      </div>

      <AnswerRecordListComponent
        records={[
          {
            answerCorrectlyCount: 5,
            avatar: QUESTIONER.avatar,
            nickname: QUESTIONER.nickname,
            tip: "发送我想你了给他",
            onClick: () => {
              history.push("/quiz-result");
            }
          },
          {
            answerCorrectlyCount: 6,
            avatar: ANSWERER.avatar,
            nickname: ANSWERER.nickname,
            tip: "发送我想你了给他",
            onClick: () => {
              history.push("/quiz-result");
            }
          }
        ]}
      />
    </div>
  );
};
