import React, { useEffect } from "react";

import "./index.scss";
import { HeaderRoundButton, AnswerRecordListComponent } from "components";
import headerImage from "./header.png";
import { Link } from "react-router-dom";

const QUESTIONER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/Jh6EtkuDZzKDCxHg6q35uBVdqW8AI2lauEd9X5llSZ4ic5yNicdnj2asnOOnKvsHmGHy7y4aPcw6OkmrDItw9yJw/132",
  nickname: "成吉思汗小跟班"
};

const ANSWERER = {
  avatar:
    "http://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKUaGkMctgfsh9WDZWGXvtpe68lCDTAFwmut0Lficc0UU1uJ8TcfC0ic3rdgIAtqSY5ibaBG6Z8qvzibw/132",
  nickname: "闫鹏强"
};

export const QuizResultPage: React.FC = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = "测试结果";
  }, []);

  return (
    <div className="quiz-result-page">
      <div className="menu">
        <HeaderRoundButton
          to="/"
          title="返回首页"
          style={{
            backgroundColor: "#333",
            position: "absolute",
            zIndex: 1,
            left: "1.5rem",
            top: "1.5rem"
          }}
        />
        <HeaderRoundButton
          to="/customer-service"
          title="联系客服"
          style={{
            backgroundColor: "red",
            position: "absolute",
            zIndex: 1,
            right: "1.5rem",
            top: "1.5rem"
          }}
        />
      </div>

      <div className="header">
        <img className="img" src={headerImage} />

        <div className="result">
          <div className="score">
            <div className="user">
              <img src={ANSWERER.avatar} className="avatar-img" />
              <div className="nickname">{ANSWERER.nickname}</div>
            </div>

            <div className="text">
              <span className="yellow">你答对了6道题</span>
              <div className="tip">用微信发“新年好”给TA</div>
              <div className="tip">用一分钟增进下你们的友谊</div>
            </div>

            <div className="user">
              <img src={QUESTIONER.avatar} className="avatar-img" />
              <div className="nickname">{QUESTIONER.nickname}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="buttons">
        <Link to="/generate-paper" style={{ textDecoration: "none" }}>
          <div className="play">我也要玩</div>
        </Link>
      </div>

      <AnswerRecordListComponent
        records={[
          {
            answerCorrectlyCount: 5,
            avatar: QUESTIONER.avatar,
            nickname: QUESTIONER.nickname,
            tip: "发送我想你了给他"
          },
          {
            answerCorrectlyCount: 6,
            avatar: ANSWERER.avatar,
            nickname: ANSWERER.nickname,
            tip: "发送我想你了给他"
          }
        ]}
      />
    </div>
  );
};
