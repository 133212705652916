import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  openId: string;
  nickname: string;
  headImgUrl: string;
}

interface InitialState {
  loading: boolean;
  error: string | null;
  value: User;
}

const search = window.location.search;
const params = new URLSearchParams(search);
const getDefaultValue = (key: string, defaultValue: string): string => {
  const value = params.get(key);
  if (value === null) {
    return defaultValue;
  } else {
    return value;
  }
};
const openId = getDefaultValue("openid", "默认ID");
const nickname = getDefaultValue("nickname", "昵称");
const headImgUrl = getDefaultValue(
  "headimgurl",
  "http://thirdwx.qlogo.cn/mmopen/g3MonUZtNHkdmzicIlibx6iaFqAc56vxLSUfpb6n5WKSYVY0ChQKkiaJSgQ1dZuTOgvLLrhJbERQQ4eMsv84eavHiaiceqxibJxCfHe/46"
);
const user = {
  openId,
  nickname,
  headImgUrl
};

export const initialState: InitialState = {
  loading: false,
  error: null,
  value: user
};

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchValueStart: state => {
      state.loading = true;
    },
    fetchValueSuccess: (state, action: PayloadAction<User>) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchValueError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  fetchValueStart,
  fetchValueSuccess,
  fetchValueError
} = slice.actions;

export default slice.reducer;
