import { configureStore } from "@reduxjs/toolkit";
import { Action, combineReducers } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import counterReducer from "./features/counter/CounterSlice";
import userReducer from "./features/user/user.slice";
import ownPapersReducer from "./features/generate-paper/generate-paper.slice";
import answerQuestionsReducer from "./features/answer-questions/answer-questions.slice";

export const rootReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
  papers: ownPapersReducer,
  quizRecord: answerQuestionsReducer
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
