import React from "react";
import Spinner from "react-spinner-material";

import "./index.scss";

const SpinnerPage: React.FC = () => {
  return (
    <div className="spinner-wrapper">
      <Spinner
        size={120}
        spinnerColor={"#333"}
        spinnerWidth={2}
        visible={true}
      />
    </div>
  );
};

export default SpinnerPage;
