import React from "react";
import { Link } from "react-router-dom";

import "./BigButton.scss";

const BigButton: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Link to="generate-paper" style={{ textDecoration: "none" }}>
      <div className="wrapper">
        <span>{title}</span>
      </div>
    </Link>
  );
};

export default BigButton;
