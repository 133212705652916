import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Question, Paper } from "../../api/api";

export const initialState: Paper[] = [];

export const slice = createSlice({
  name: "AnswerQuestions",
  initialState,
  reducers: {
    answerQuestions: (state, action: PayloadAction<Question[]>) => {
      state.push(action.payload);
    }
  }
});

export const { answerQuestions } = slice.actions;

export default slice.reducer;
