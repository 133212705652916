import React from "react";

import "./index.scss";

interface AnswerRecord {
  avatar: string;
  nickname: string;
  answerCorrectlyCount: number;
  tip: string;
  onClick?: () => void;
}

export const AnswerRecordListComponent: React.FC<{
  records: AnswerRecord[];
}> = ({ records }) => {
  return (
    <div className="records-component">
      {records.length > 0 &&
        records.map(
          ({ answerCorrectlyCount, avatar, nickname, tip, onClick }, idx) => (
            <div key={idx} className="record" onClick={onClick}>
              <img src={avatar} className="avatar" />
              <div className="text">
                <div className="nickname">{nickname}</div>
                <div className="tip">{tip}</div>
              </div>
              <div className="answer-correctly-text">{`答对 ${answerCorrectlyCount} 题`}</div>
            </div>
          )
        )}
    </div>
  );
};
