import React from "react";

import "./index.scss";

export const FooterComponent: React.FC = () => {
  return (
    <div className="footer">
      <span className="font">©2020 牵千</span>
    </div>
  );
};
