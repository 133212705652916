import React from "react";

import "./index.scss";
import { HeaderRoundButton } from "components";

export const CustomerServicePage: React.FC = () => {
  return (
    <div className="customer-service">
      <HeaderRoundButton
        to="/"
        title="返回首页"
        style={{
          backgroundColor: "#333",
          position: "absolute",
          zIndex: 1,
          left: "1.5rem",
          top: "1.5rem"
        }}
      />

      <div className="body">
        <span className="account">客服公众号: 牵千之恋</span>
        <img
          className="qrcode"
          src="https://open.weixin.qq.com/qr/code?username=qianqianzhilianlove"
        />
        <span className="tips">
          常按扫描二维码
          <br />
          添加公众号客服
        </span>
      </div>
    </div>
  );
};
