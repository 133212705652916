import React from "react";

import "./index.scss";
import adventureImg from "./adventure-game.jpeg";
import { HeaderRoundButton } from "components";

const rules = [
  "答对十题：太默契了，今天向TA表白",
  "答对九题：做TA一天男/女朋友",
  "答对八题：让TA给你说个小秘密",
  "答对七题：来吧，让TA请你喝杯奶茶",
  "答对六题：用微信发“想你了”给TA",
  "答对五题：咦~帮TA点一分外卖吧",
  "答对四题：来吧，请Ta喝杯奶茶",
  "答对三题：给TA发6.6红包"
];

export const HowToPlayPage: React.FC = () => {
  return (
    <div className="how-to-play">
      <HeaderRoundButton
        to="/"
        title="返回首页"
        style={{
          backgroundColor: "#333",
          position: "absolute",
          zIndex: 1,
          left: "1.5rem",
          top: "1.5rem"
        }}
      />
      <div className="card">
        <img className="adventure-img" src={adventureImg} />
        <div className="rules">
          {rules.map((rule, idx) => (
            <div key={idx}>
              <span>{rule}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
