import React from "react";
import classNames from "classnames";

import "./index.scss";

export interface Option {
  label: string;
  isChecked: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const OptionsComponent: React.FC<{ options: Option[] }> = ({
  options
}) => {
  return (
    <div className="options-component">
      {options.map(({ isChecked, label, onClick }, idx) => {
        return (
          <div
            key={idx}
            className={
              isChecked ? classNames("option", "option-checked") : "option"
            }
            onClick={onClick}
          >
            {isChecked && (
              <img className="checkbox" src={require("./images/checked.png")} />
            )}
            {!isChecked && (
              <img
                className="checkbox"
                src={require("./images/unchecked.png")}
              />
            )}
            <span>{label}</span>
          </div>
        );
      })}
    </div>
  );
};
